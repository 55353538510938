import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>PRESKÚMAJTE porovnanie produktov</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkty/',
    label: 'POZRITE SA NA VŠETKY PRODUKTY',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li><sup>*</sup>plne naplnená umývačka špinavým riadom</li>\n<li><sup>**</sup>vs Finish Power podľa priemernej váhy</li>\n<li><sup>***</sup>účinne odstraňuje pripálené nečistoty</li>\n<li><sup>****</sup>ingrediencie: enzýmy</li>\n</ul>',
      rows: [
        {
          text: 'Čistota',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt548886b50983d27c/676010ef2440029b780829bd/clean.svg',
        },
        {
          text: 'Lesk',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt48d4b60156c7218b/676010efbc1c7eef444fc4a7/shine.svg',
        },
        {
          text: '48h zaschnuté škvrny*',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2cd64be01c35fbfe/676010efc139ca3db804a901/dried-on.svg',
        },
        {
          text: 'Odolnosť voči mastnote',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt47e7b26c7ba2b0b4/676010ef6dece33fa985ea7d/tough-on-grease.svg',
        },
        {
          text: 'Bez predumývania',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2d5709e63e6623f7/676016be7eeb6a7440346a61/skip-the-rinse.svg',
        },
        {
          text: 'Kapsule bez obalu',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltb7d89278c14f4713/6760178388255429f0b965d4/wrapper-free-tabs.svg',
        },
        {
          text: 'Rýchle rozpúšťanie',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2f7a6152652d76e8/676010ef9dd7b854687c9886/fast-dissolving.svg',
        },
        {
          text: 'Menšia chemická váha**',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6447c1f8daaebefe/676010efde48ee6659718ad2/less-chemical-weight.svg',
        },
        {
          text: 'Ochrana skla',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt3d2b17b15633d88c/676010ef555bf7acf12efba3/glass-protection.svg',
        },
        {
          text: 'Posilnenie sviežosti',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6eafd0dfeea02817/676010efde48ee47fe718ad6/freshness-boost.svg',
        },
        {
          text: 'Pripálené svkrny***',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt20c43f52ba0816a0/676010efd5410f241f0e0f99/burnt-on-stains.svg',
        },
        {
          text: 'Správna ingrediencie v správny čas****',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt98da18bd8dc1eeb4/676010ef06d85974547b5df4/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltfa5cb0f359674ff7/6761784b036366e84e442bfc/finish-ultimate-plus_(1).webp',
          productName: 'ULTIMATE PLUS',
          productLink: '/produkty/kapsuly/finish-ultimate-plus-all-in-1/45-regular/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7fba4a9bcd155d3e/67617856531c391c16068f69/finish-ultimate_(1).webp',
          productName: 'ULTIMATE',
          productLink: '/produkty/kapsuly/ultimate-all-in-1/60-regular/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt9466ad366b9a9c02/6761785f7f85c043266d57dd/finish-quantum_(1).webp',
          productName: 'QUANTUM',
          productLink: '/produkty/kapsuly/quantum-all-in-1/72-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8b6320fb4b395a15/67617867046133a3f00ad781/finish-power.webp',
          productName: 'POWER',
          productLink: '/produkty/tablety/power-all-in-1/94-regular/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
    {
      title: 'FINISH 0%',
      legend: '<ul>\n<li><sup>*</sup>vs Finish Power podľa priemernej váhy</li>\n</ul>',
      rows: [
        {
          text: 'Eco certifikát',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blta27a535e4bb82354/67603476015cdfcf5ab90f30/svgviewer-png-output_(1).png',
        },
        {
          text: 'Čistota',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt548886b50983d27c/676010ef2440029b780829bd/clean.svg',
        },
        {
          text: 'Lesk',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt48d4b60156c7218b/676010efbc1c7eef444fc4a7/shine.svg',
        },
        {
          text: 'Odolnosť voči mastnote',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt47e7b26c7ba2b0b4/676010ef6dece33fa985ea7d/tough-on-grease.svg',
        },
        {
          text: 'Bez predumývania',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2d5709e63e6623f7/676016be7eeb6a7440346a61/skip-the-rinse.svg',
        },
        {
          text: 'Rýchle rozpúšťanie',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2f7a6152652d76e8/676010ef9dd7b854687c9886/fast-dissolving.svg',
        },
        {
          text: 'Menšia chemická váha*',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6447c1f8daaebefe/676010efde48ee6659718ad2/less-chemical-weight.svg',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8b077cc4d5c6c4eb/6761786dfc6f9faa299e1d57/finish-ultimate-0.webp',
          productName: 'ULTIMATE ALL IN 1 0%',
          productLink: '/produkty/kapsuly/ultimate-0/45-regular/',
          color: 'light-green-gradient',
          values: [
            {
              image:
                'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt73fff7ba23ebeab0/676041e13b2e632dc32e6733/svgviewer-png-output_(2).png',
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd99afc0b2b4ac4f1/67617798cb79e85d6e23384c/finish-ultimate.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produkty/kapsuly/ultimate-all-in-1/60-regular/',
        text: 'PRESKÚMAJTE ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt3b59c2a04ace495c/676177e8e5103f05bc3da207/1_(1).webp',
          name: 'Odolný voči mastnote',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt18ab1162b7c6d93b/676177c3e9e1787ce6ac0806/2.webp',
          name: '<strong>48H</strong><br/>zaschnuté škvrny',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8f389f8a70af189d/676177a09d1bf363a1e01dd3/finish-ultimate-plus.webp',
      cardStyle: 'black',
      button: {
        link: '/produkty/kapsuly/finish-ultimate-plus-all-in-1/45-regular/',
        text: 'PRESKÚMAJTE NÁŠ NAJLEPŠÍ ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7f20901df5082ba2/676177cb0363669312442bf8/1-gold.webp',
          name: '<strong>3X</strong> silnejšia čistiaca sila*',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt102713d86013ff95/676177d46dece3126e85ecf8/gold-2.webp',
          name: 'Odolný voči mastnote',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt04b603a98fdf64af/676177e0244002273a082bfd/gold-3.webp',
          name: 'Pripálené škvrny',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6caceaaaaecc3fa2/676177a7098d3935ff242b7c/finish-quantum.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produkty/kapsuly/quantum-all-in-1/72-regular/',
        text: 'PRESKÚMAJTE QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt3b59c2a04ace495c/676177e8e5103f05bc3da207/1_(1).webp',
          name: 'Odolný voči mastnote',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  description:
    'Melden Sie sich über das folgende Formular für den Finish Newsletter an und bleiben Sie immer auf dem neuesten Stand rund um Finish! Mit betätigen des ANMELDEN-Buttons willigen Sie ein, von RB Hygiene Home Deutschland GmbH Informationen zu aktuellen Angeboten, Aktionen und anderen Neuigkeiten zur Finish Marke per E-Mail zu erhalten.',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
